/*@Injectable({
  providedIn: root
})
export class GameService {*/

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Geolocation } from "@awesome-cordova-plugins/geolocation/ngx";
import { ConstantsService } from "./constants.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Socket } from "ngx-socket-io";

declare let google;

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  // Observable for geo location coordinates
  private geoLocationCoordsSubject = new BehaviorSubject<GeoLocationCoordsTypes | null>(null);
  geoLocationCoords: Observable< GeoLocationCoordsTypes | null> = this.geoLocationCoordsSubject.asObservable()

  private geoLocationStatusSubject = new BehaviorSubject<GeoLocationStatusTypes>({ code: -1, message: '' })
  geoLocationStatus: Observable<GeoLocationStatusTypes> = this.geoLocationStatusSubject.asObservable()

  //Observable for currency information
  private currencyInfoSubject = new BehaviorSubject<CurrencyInfoTypes | null>(null)
  currencyInfo: Observable<CurrencyInfoTypes | null> = this.currencyInfoSubject.asObservable()

  constructor(
    private geolocation: Geolocation,
    private http: HttpClient,
    private constants: ConstantsService,
    private socket: Socket,
  ) {
    this.ngOnInit()
    this.socket.on('country_code', (data) => {
      this.currencyInfoSubject.next(data)
    })
  }

  async ngOnInit() {
    await this.getCurrentLocation()
  }

  getCurrencyInfo(): CurrencyInfoTypes {
    return this.currencyInfoSubject.getValue()
  }


  /**
   * 
   * Get country from address 
   * @param address 
   * @returns 
   */
  async getCountryFromAddress(address: any): Promise<void> {
    if (address) {
      const country = address.address_components.find((component) =>
        component.types.includes('country')
      );
      return country?.short_name
    }
  }

  /**
   * Get the current location for the user
   * Cache the location for 5 minutes and timeout after 1 minute
   * If there is an error, return an empty lat and long
   */
  async getCurrentLocation(): Promise<{ lat: number, long: number }> {
    let lat: number;
    let long: number;

    try {
      const options = { timeout: 20000, maximumAge: 300000 };
      const response = await this.geolocation.getCurrentPosition(options);

      lat = response.coords.latitude;
      long = response.coords.longitude;
      this.geoLocationStatusSubject.next({ code: 0, message: 'success' })
      this.geoLocationCoordsSubject.next({ lat: lat, long: long });

      return { lat: lat, long: long }
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        // Now we know the error is an instance of GeolocationPositionError
        console.error('Geolocation error:', error);
        this.geoLocationStatusSubject.next(error);
        this.geoLocationCoordsSubject.next({ lat: null, long: null });

      } else {
        // Handle other types of errors
        console.error('Unknown error:', error);
        this.geoLocationStatusSubject.next({ code: 4, message: 'Unknown Error' });
        this.geoLocationCoordsSubject.next({ lat: null, long: null });

      }
    }

    return { lat, long };

  }

}